import React from "react";
import SugarCounter from "../../atoms/SugarCounter/SugarCounter";

import style from "./Sidebar.module.css";
import useSelectedItemsContext from "../../../hooks/useSelectedItemsContext";
import Icon from "../../atoms/Icon/Icon";

const Sidebar = () => {
  const {
    context: { items },
  } = useSelectedItemsContext();

  return (
    <div className={style.sidebar}>
      <div className={style.productList}>
        <Icon icon="cart" className={style.cartIcon} />
        {items.length > 0 && (
          <div className={style.productListList}>
            {items.map((item) => (
              <p className={style.productName}>{item.product_name}</p>
            ))}
          </div>
        )}

        {items.length === 0 && (
          <p className={style.noItems}>
            Veuillez sélectionner / chercher un produit
          </p>
        )}
      </div>
      <div className={style.bottom}>
        <SugarCounter />
        <p className={style.info}>
          Le taux journalier recommandé par l’OMS est de 25g
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
