import React from "react";
import Result from "../../../types/Result";

import Button from "../../atoms/Button/Button";

import useSelectedItemsContext from "../../../hooks/useSelectedItemsContext";

import style from "./ResultItem.module.css";

const ResultItem = ({ item }) => {
  const { addItem } = useSelectedItemsContext();

  const handleAdd = () => {
    addItem(item);
  };

  return (
    <div className={style.card}>
      <img
        className={style.cardImage}
        src={item.image_url}
        width={300}
        alt=""
      />
      <div className={style.cardContent}>
        <h2 className={style.title}>{item.product_name}</h2>
        <p className={style.sugar}>
          Sucre :{" "}
          {`${item.nutriments.sugars_value}${item.nutriments.sugars_unit}`}
        </p>
        <Button label="Ajouter" onClick={handleAdd} className={style.button} />
      </div>
    </div>
  );
};

ResultItem.propTypes = {
  item: Result,
};
export default ResultItem;
