import PropTypes from "prop-types";

export default PropTypes.shape({
  product_name: PropTypes.string,
  image_url: PropTypes.string,
  nutriments: PropTypes.shape({
    sugars_value: PropTypes.string,
    sugars_unit: PropTypes.string,
  }),
});
