import React, { createContext, useState } from "react";

const defaultState = {
  items: [],
};

export const SelectedItemsContext = createContext([defaultState, () => {}]);

export const SelectedItemsProvider = ({ children, value }) => {
  const [state, setState] = useState(value || defaultState);

  return (
    <SelectedItemsContext.Provider value={[state, setState]}>
      {children}
    </SelectedItemsContext.Provider>
  );
};
