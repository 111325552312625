import React from "react";
import PropTypes from "prop-types";

import Cart from "./Cart/Cart";

const Icon = ({ icon, ...props }) => {
  switch (icon) {
    case "cart":
      return <Cart {...props} />;
    default:
      throw new Error("unknow icon " + icon);
  }
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default Icon;
