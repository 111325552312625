import React from "react";
import PropTypes from "prop-types";

const Input = ({ name, id, onChange, placeholder, className, onKeyDown }) => {
  return (
    <input
      className={className}
      type="text"
      name={name}
      id={id}
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
    />
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
};

Input.defaultProps = {
  placeholder: "",
  className: null,
  onKeyDown: null,
};

export default Input;
