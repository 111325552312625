import React from "react";

import useSelectedItemsContext from "../../../hooks/useSelectedItemsContext";

import style from "./SugarCounter.module.css";

const SugarCounter = () => {
  const {
    context: { items },
  } = useSelectedItemsContext();

  const value = items.reduce(
    (acc, item) => acc + parseFloat(item.nutriments.sugars_value),
    0
  );

  return <div className={style.wrapper}>Sucre : {value.toFixed(2)}g</div>;
};

SugarCounter.propTypes = {};

export default SugarCounter;
