import React, { useState } from "react";
import ResultList from "./components/molecules/ResultList/ResultList";
import Search from "./components/molecules/Search/Search";
import { SelectedItemsProvider } from "./store/SelectedItemsContext";

import style from "./App.module.css";
import Sidebar from "./components/molecules/Sidebar/Sidebar";

function App() {
  const [result, setResult] = useState([]);

  return (
    <SelectedItemsProvider>
      <div className={style.app}>
        <section>
          <Search setResult={setResult} />
          <ResultList items={result} />
        </section>
        <aside className={style.aside}>
          <Sidebar />
        </aside>
      </div>
    </SelectedItemsProvider>
  );
}

export default App;
