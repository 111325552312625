import { useContext } from "react";
import { SelectedItemsContext } from "../store/SelectedItemsContext";

const useSelectedItemsContext = () => {
  const [state, setState] = useContext(SelectedItemsContext);

  const addItem = (item) => {
    setState((prevState) => {
      return { items: [...prevState.items, item] };
    });
  };
  const removeItem = () => {};

  return { context: state, addItem, removeItem };
};

export default useSelectedItemsContext;
