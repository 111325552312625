import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../../atoms/Input/Input";
import Button from "../../atoms/Button/Button";

import search from "../../../utils/search";

import style from "./Search.module.css";

const Search = ({ setResult }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const searchTermThreshold = 3;

  const handleSearch = async () => {
    if (searchTerm !== null && searchTerm.length >= searchTermThreshold) {
      const result = await search(searchTerm);
      setResult(result.products);
    }
  };

  const handleEnter = (e) => {
    if (
      e.key === "Enter" &&
      searchTerm &&
      searchTerm.length > searchTermThreshold
    ) {
      handleSearch();
    }
  };

  return (
    <div className={style.wrapper}>
      <Input
        name="product"
        id="product"
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Cherchez un produit ..."
        className={style.input}
        onKeyDown={handleEnter}
      />
      <Button
        label="Chercher"
        onClick={handleSearch}
        disabled={searchTerm === null || searchTerm.length < 3}
        className={style.button}
      />
    </div>
  );
};

Search.propTypes = {
  setResult: PropTypes.func.isRequired,
};

export default Search;
