import React from "react";
import PropTypes from "prop-types";
import ResultItem from "../ResultItem/ResulItem";
import Result from "../../../types/Result";

import style from "./ResultList.module.css";

const ResultList = ({ items }) => {
  return (
    <div className={style.list}>
      {items.length > 0 && items.map((item) => <ResultItem item={item} />)}
      {items.length === 0 && <p className={style.noItems}>Aucun produit ...</p>}
    </div>
  );
};

ResultList.propTypes = {
  items: PropTypes.arrayOf(Result).isRequired,
};

export default ResultList;
