const requestOptions = () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("User-Agent", "IndigluPOC");

  return {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
};

export default async (term) => {
  const API_URL = `https://world.openfoodfacts.org/cgi/search.pl?action=process&search_terms=${term}&search_simple=1&json=1`;

  const response = await fetch(API_URL, requestOptions());
  return response.json();
};
